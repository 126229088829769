<template>
  <div class="row" id="my-container">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Monthly result form</h4>
          <form @submit.prevent="generateMonthlyClosing">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Select year</label>
                  <select class="form-control" required v-model="selectedYear">
                    <option :value="ys" v-for="ys in years">{{ ys }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Select month</label>
                  <select class="form-control" required v-model="selectedMonth">
                    <option :value="ms.value" v-for="ms in months">{{ ms.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button type="submit" class="btn btn-success"> Download monthly result</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Generated link <small class="text-danger">(download link are available to 10 mins for
            security purposes)</small></h4>
          <table class="table ">
            <template v-for="(link,i) in tokens">
              <template v-if="link['status'] === 'failed'">
                <tr>
                  <td>Generated {{ getDownloadDate(link['created_at']) }}</td>
                  <td class="text-danger text-bold">{{ link['status'] }}</td>
                  <td>{{ link['status_description'] }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>Generated {{ getDownloadDate(link['created_at']) }}</td>
                  <td class="text-success">{{ link['status'] }}</td>
                  <td><a :href="getDownloadUrl(link.token)">Click to download</a></td>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>


    </div>

  </div>
</template>

<script>

import Sidepanel from "../f-administration/f-sheet-sidepanel";
import DatePicker from "vue2-datepicker";

import moment from "moment";

export default {
  name: "monthly-closing",
  components: {
    Sidepanel,
    DatePicker,

  },
  data() {
    return {
      download_link: '',
      popoverShow: false,
      startWeek: '',
      periods: [],
      endWeek: '',
      date_range: [],
      driverList: [],
      outputDetails: [],
      selectedYear: null,
      generateNewOrExistingData: 'new',
      weeks: [],
      tokens: [],
      selectedMonth: '',
      months: [
        {name: 'January', value: '01'},
        {name: 'February', value: '02'},
        {name: 'March', value: '03'},
        {name: 'April', value: '04'},
        {name: 'May', value: '05'},
        {name: 'June', value: '06'},
        {name: 'July', value: '07'},
        {name: 'August', value: '08'},
        {name: 'September', value: '09'},
        {name: 'October', value: '10'},
        {name: 'November', value: '11'},
        {name: 'December', value: '12'},

      ],
      years: [],
    }
  },

  methods: {
    weekTagValidator(tag) {
      // Individual tag validator function
      try {
        const num = JSON.parse(tag);
        if (isNaN(tag)) {
          return false
        } else if (!Number.isInteger(num)) {
          return false
        } else if (num < 1 || num > 53) {
          return false
        } else {
          return true;
        }
      } catch (e) {
      }

    },

    generateMonthlyClosing() {

      const payload = {
        'year': this.selectedYear,
        'month': this.selectedMonth,
      }

      this.$store.dispatch('generateMonthlyResult', payload).then(() => {
        // const url = this.getDownloadUrl(token);
        // location.href = url;
      })

    },


    popOpen(trigger) {
      this.trigger = trigger;
      this.popoverShow = true;
    },
    popClose() {
      this.popoverShow = false;
    },
    removeDate() {
      this.date_range = [];
    },

    getDownloadUrl(token) {
      return process.env.VUE_APP_API_BASE_URL + '/download-monthly-closing/' + token;
    },

    getGeneratedMonthlyClosing() {
      this.$store.dispatch('getGeneratedMonthlyResult').then((res) => {
        this.tokens = res;
      })

    },
    onDownloadLinkTapped() {
      setTimeout(() => {
        this.getUni4DownloadTokens()
      }, 2000)
    },


    dateRangeChanged() {
      this.usePeriods = false;
    },

    getDownloadDate(createdAt) {
      return moment(createdAt).calendar();
    },

    sortPeriods() {
      this.periods.sort(function (a, b) {
        return a - b;
      });
    },

    addAllPeriods() {
      this.periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },

    removeAllPeriods() {
      this.periods = [];
    },
  },


  watch: {
    driver: function (driver) {
      // if(driver){
      //
      // }
      // this.generateFinancialSheet(this.usePeriods)
    },
    usePeriods: function (value) {
      if (!value) {
        this.removeAllPeriods();
      }
    },
    '$store.getters.GET_AVAILABLE_YEARS': function (ys) {
      this.years = ys;
      if (this.years.length > 1) {
        this.selectedYear = this.years[0]
      }

    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Monthly Results')
    this.$store.dispatch('getAvailableYears');
    this.getGeneratedMonthlyClosing();
  },

  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      let {data} = event;
      if (data.title === 'monthly_closing') {
        console.log('title')
        this.$store.dispatch('setProcessingInfo', event.heading);
      } else if (data.title === 'monthly_closing_completed') {
        console.log('completed')
        this.getGeneratedMonthlyClosing();
      }
    });
  }
}
</script>

<style scoped>
.line-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>