<template>
  <div class="inbox-leftbar">
    <!--    <b-button block variant="danger" @click="showModal = true">Compose</b-button>-->
    <!-- start search box -->
    <form class="search-bar">
      <div class="position-relative">
        <input
            type="text"
            class="form-control form-control-light"
            placeholder="Search Driver ..."
            @keyup="onSearch"
        />
        <span class="mdi mdi-magnify"></span>
      </div>
    </form>
    <!-- end search box -->

    <hr>
    <ul class="list-unstyled">

      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showPremiumPartners"
        > Premium partners
        </b-checkbox>
      </li>
      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showMediumPartners"
        > Medium partners
        </b-checkbox>
      </li>
      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showLightPartners"
        > Light partners
        </b-checkbox>
      </li>
      <li class="mb-1">
        <b-checkbox
            @change="onDriverTypeChanged"
            v-model="showLightPartnerPlus"
        > Light partner plus
        </b-checkbox>
      </li>
    </ul>
    <hr class="mb-0">

    <div class="mail-list mt-2">

      <template v-if="isSearching">
        <skeleton :count="5"></skeleton>
      </template>
      <template v-else>
        <b-checkbox class="mb-3"
                    @change="selectAllFxn"
                    v-if="filteredDrivers.length >0"
                    v-model="select_all"
        > Select All
        </b-checkbox>
        <template v-for="(driver, index) in filteredDrivers">
        <div class="media mb-4" style="cursor:pointer;" :key="driver['id']"
               :class="{'active-driver': selectedIndex.indexOf(driver['id']) >-1}"
               @click="driverSelected(driver, index)">
            <div class="position-relative">
              <template v-if="selectedIndex.indexOf(driver['id']) >-1">
                <b-avatar size="md" variant="light" class="text-dark"
                ><i class="fa fa-check "></i></b-avatar
                >
              </template>
              <template v-else>
                <b-avatar size="md" variant="dark" class="text-light"
                ><span class="">{{ driver.name | initials }}</span></b-avatar
                >
              </template>

            </div>
            <div class="media-body">
              <h5 class="mt-0 mb-0 ml-2 font-14">
                {{ driver.name }} {{ driver['other_names'] }} <span class="badge badge-soft-success">Dossier: {{driver.driver.dossier}}</span>
                <template v-if="driver['countOnlyPendingFiles'] && driver['countOnlyPendingFiles'] > 0">
                  <span class="badge badge-soft-danger float-right ml-2"
                        @click="driverSelected(driver, index)">{{ driver['countOnlyPendingFiles'] }}</span>
                </template>
              </h5>
              <p class="mt-1 mb-0 ml-2 text-muted font-14">
                <span class="w-75">status: {{ driver.status }}
                  <template v-if="driver['countPendingReceiptAdminMessagesFromDriver'] > 0">
                   <span
                       class="float-right text-muted font-weight-normal font-12"
                   ><span
                       class="badge badge-pill p-1 badge-success">{{
                       driver['countPendingReceiptAdminMessagesFromDriver']
                     }}</span> </span
                   >
                </template>
                </span>
              </p>
            </div>
          </div>
        </template>

      </template>


    </div>


  </div>
</template>


<script>

import {Skeleton} from 'vue-loading-skeleton';


/**
 * Side-panel component
 */
export default {

  components: {
    Skeleton
  },
  props: {
    status: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      activeIndex: 0,
      filteredDrivers: [],
      showPremiumPartners: true,
      showMediumPartners: true,
      showLightPartners: true,
      select_all: false,
      showLightPartnerPlus: true,
      firstCreated: true,
      selectedIndex: []
    };
  },
  computed: {
    drivers() {

      return this.$store.getters.GET_DRIVERS;
    },
    isSearching() {
      return this.$store.getters.SECTION_LOADER;
    }
  },
  watch: {
    drivers: function (drivers) {
      if (this.filteredDrivers.length < 1 && this.firstCreated === true) {
        // console.log('watch filtered drivers called', drivers)
        this.filteredDrivers = drivers
        this.firstCreated = false;
      }
    }

  },
  methods: {
    selectAllFxn() {
      if (this.select_all) {
        this.selectedIndex = this.filteredDrivers.map((e) => e.id)
        this.$emit('onDriverSelected', this.filteredDrivers, true)
      } else {
        this.selectedIndex = [];
        this.$emit('onDriverSelected', [], true)
      }
    },

    onSearch(event) {
      let value = event.target.value;
      if (value === '') {
        this.filteredDrivers = this.drivers;
        this.showLightPartnerPlus = true
        this.showPremiumPartners = true
        this.showMediumPartners = true
        this.showLightPartners = true
        return;
      } else {
        this.showLightPartnerPlus = false
        this.showPremiumPartners = false
        this.showMediumPartners = false
        this.showLightPartners = false
      }

      this.filteredDrivers = this.drivers.filter(
          e => e['name'].toLowerCase().includes(value.toLowerCase())
              || (e['other_names'] && e['other_names'].toLowerCase().includes(value.toLowerCase()))
              || e['driver']['dossier'].toLowerCase().includes(value.toLowerCase())
      )
    },
    driverSelected(driver, index) {
      let ind = this.selectedIndex.indexOf(driver.id);
      if (ind > -1) {
        this.selectedIndex.splice(ind, 1);
      } else {
        this.selectedIndex.push(driver.id)
      }
      this.$emit('onDriverSelected', driver, false)
    },
    onDriverTypeChanged() {

      this.filteredDrivers = [];

      if (this.showPremiumPartners) {
        const premiumPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'premium_partner'
        )
        this.filteredDrivers.push(...premiumPartnerTypes)
      }

      if (this.showLightPartners) {
        const lightPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'light_partner'
        )
        this.filteredDrivers.push(...lightPartnerTypes)
      }
      //
      if (this.showLightPartnerPlus) {
        const lightPlusPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'light_partner_plus'
        )
        this.filteredDrivers.push(...lightPlusPartnerTypes)
      }
      //
      if (this.showMediumPartners) {
        const mediumPartnerTypes = this.drivers.filter(
            e => e['prospective_driver']['service_type'].toLowerCase() === 'medium_partner'
        )
        this.filteredDrivers.push(...mediumPartnerTypes)
      }
      this.selectAllFxn();

    },

    getDrivers(showLoader = true) {
      console.log('get drivers called..')
      this.$store.dispatch('getDrivers', {limit: 1000, status: this.status, showLoader: showLoader});
    },


  },

  created() {
    console.log('created called')
    this.getDrivers(true)
  },
};
</script>

<style scoped>
.active-driver {
  /*background-color: #f3f7f9;*/
  /*border-color: #f3f7f9;*/
  /*padding-top: 10px;*/
  /*padding-bottom: 10px;*/
  /*!*padding-left: 1px;*!*/
  /*!*padding-right: 1px;*!*/
  /*border-radius: 5px;*/
}
</style>